$("[data-scroll-top]").click(function(event) {
	event.preventDefault();

	var alvo = $(this).attr('href'),
		top = $(alvo).offset().top;

	$('body').animate({
		scrollTop: top},
		1000, function() {
		/* stuff to do after animation is complete */
	});
});

$(document).scroll(function(event) {
	var t = $(this).scrollTop(),
		b = $(".banners"),
		bt = b.offset().top,
		bh = b.height(),
		mt = bt + bh,
		alvo = $(".topo");

		if(t > mt) {
			$(alvo).addClass('topo-fixed');
		}else{
			$(alvo).removeClass('topo-fixed');
		}
});